<template>
  <el-dialog width="700px" v-bind="$attrs" v-on="$listeners">
    <el-form :model="formData" label-width="120px" ref="form">
      <el-form-item
        label="平台标识："
        class="form-item-flex"
        :rules="[$formRules.required('请上传平台标识')]"
        prop="logo"
      >
        <div class="f-form-block">
          <div class="upload-group">
            <f-upload
              required
              :type="2"
              size="mini"
              :default-file-list="formData.logo ? [formData.logo] : undefined"
              @on-success="logoUploadSuccess($event, 'logo')"
            ></f-upload>
            <el-form-item
              v-if="formData.type === 2"
              :rules="[$formRules.required('请上传小程序首页logo')]"
              prop="mpLogo"
            >
              <f-upload
                required
                :type="2"
                size="mini"
                :default-file-list="
                  formData.mpLogo ? [formData.mpLogo] : undefined
                "
                @on-success="logoUploadSuccess($event, 'mpLogo')"
              ></f-upload>
            </el-form-item>
          </div>
          <p class="tips">
            图1：请上传应用标识图片，与微信小程序注册LOGO保存一致；图片必须清晰可见，尺寸尽量采用1:1规格，<br />
            <template v-if="formData.type === 2">
              图2：请上传小程序首页顶部logo图片；<br />
            </template>
            支持.jpg.jpeg.png.bmp格式；大小不超过2M；
          </p>
        </div>
      </el-form-item>
      <el-form-item
        label="平台名称："
        :rules="[$formRules.required()]"
        prop="name"
      >
        <div class="input-group">
          <el-input disabled v-model="formData.applicationName"></el-input>
          <el-input
            placeholder="请输入"
            v-model="formData.name"
            maxlength="30"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        v-if="formData.type === 2"
        label="业务配置："
        prop="businessSetting"
        :rules="[$formRules.required()]"
      >
        <el-checkbox-group v-model="formData.businessSetting">
          <el-checkbox
            v-for="item of businessList.slice(0, 2)"
            :key="item.value"
            :label="item.value"
            :disabled="item.value == 1"
          >
            <strong>{{ item.label }}</strong>
            <span>{{ item.desc }}</span>
          </el-checkbox>
        </el-checkbox-group>
        <div class="choice-check">
          <div>以下业务只能二选一，不能同时授权</div>
          <el-checkbox-group v-model="formData.businessSetting">
            <el-checkbox
              v-for="item of businessList.slice(2, 4)"
              :key="item.value"
              :label="item.value"
              @change="changeRadio"
            >
              <strong>{{ item.label }}</strong>
              <span>{{ item.desc }}</span>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="$emit('update:visible', false)">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FUpload from "@/components/upload";
import { editAppInfo } from "@/api/platform";
const formData = {
  id: null,
  applicationId: null, // 所属平台
  logo: "",
  mpLogo: "", //小程序首页logo
  name: "",
  type: "", //平台类型 1 购房平台 2分销平台 只做判断用
  applicationName: "", //平台应用名称 只做展示用
  businessSetting: [] // 业务配置
};
export default {
  components: {
    FUpload
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      formData: this.$deepClone(formData),
      businessList: [
        {
          label: "基础应用",
          desc: "楼盘管理、平台员工、报备带看、渠道平台（PCN渠道）等基础业务",
          value: "1"
        },
        {
          label: "自有渠道",
          desc:
            "开放渠道用户注册（含邀请注册）、自有渠道、小程序报备等相关业务",
          value: "2"
        },
        {
          label: "认购签约",
          desc: "房源销控、认购签约、甲方结算、营销报告等相关业务",
          value: "3"
        },
        {
          label: "成交管理",
          desc: "申报成交、应收台账等简单成交管理业务",
          value: "4"
        }
      ]
    };
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        if (val) {
          for (const key in formData) {
            this.formData[key] = val[key];
          }
          this.formData.businessSetting = val.businessSetting.split(",");
        }
      }
    }
  },
  methods: {
    logoUploadSuccess([file], filed) {
      if (file) {
        this.formData[filed] = file.url;
      }
    },
    // 二选一业务单选框选择
    changeRadio(e, value) {
      const index = val => {
        return this.formData.businessSetting.indexOf(val.toString());
      };
      if (e && value.target._value == 3 && index(4) >= 0)
        this.formData.businessSetting.splice(index(4), 1);
      if (e && value.target._value == 4 && index(3) >= 0)
        this.formData.businessSetting.splice(index(3), 1);
    },
    //提交表单
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const params = this.$deepClone(this.formData);
        params.businessSetting = params.businessSetting
          .filter(item => item)
          .join(",");
        const res = await editAppInfo(params);
        if (res) {
          this.$showSuccess("提交成功");
          this.formData = this.$deepClone(formData);
          this.$refs.form.resetFields();
          this.$emit("update:visible", false);
          this.$emit("success");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.upload-group {
  display: flex;
}
.tips {
  margin-top: 20px;
  color: #999;
}
.input-group {
  display: flex;
  ::v-deep {
    .el-input.is-disabled {
      width: 200px;
      margin-right: 10px;
    }
  }
}
::v-deep {
  .el-checkbox {
    padding-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .el-checkbox__label {
      strong {
        color: #333;
      }
      span {
        padding-top: 5px;
        display: block;
        color: #999;
      }
    }
    .is-disabled.is-checked {
      .el-checkbox__inner {
        color: #fff;
        background-color: $primary;
        border-color: $primary;
        opacity: 0.7;
      }
    }
  }
}
.choice-check {
  background: #fafafc;
  border-radius: 4px;
  padding: 14px 18px 12px;
  margin-top: 12px;
  margin-left: -18px;
  & > div:first-child {
    line-height: 14px;
    margin-bottom: 10px;
  }
}
</style>

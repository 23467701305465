var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "platform base-container"
  }, [_vm._m(0), _c('el-table', {
    staticClass: "body",
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "平台信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          staticClass: "x aic"
        }, [_c('el-image', {
          staticClass: "logo",
          attrs: {
            "src": scope.row.logo,
            "fit": "cover"
          }
        }, [_c('div', {
          staticClass: "image-slot",
          attrs: {
            "slot": "error"
          },
          slot: "error"
        }, [_c('i', {
          staticClass: "el-icon-picture-outline"
        })])]), _c('div', {
          staticClass: "y3",
          staticStyle: {
            "height": "50px"
          }
        }, [_c('p', {
          staticClass: "fs16"
        }, [_vm._v(" " + _vm._s(scope.row.name) + "-" + _vm._s(scope.row.applicationName) + " ")]), _c('p', {
          staticClass: "color9"
        }, [_vm._v(" " + _vm._s(scope.row.enterpriseName) + "，" + _vm._s(scope.row.inDate) + " ")])])], 1);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "类型",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {}, [_vm._v(" " + _vm._s(scope.row.type == 1 ? "购房平台" : "分销平台") + " ")]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "平台终端",
      "width": "210px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _row$terminalList, _row$terminalList2;
        var row = _ref.row;
        return _c('div', {
          staticClass: "x aic"
        }, [(_row$terminalList = row.terminalList) !== null && _row$terminalList !== void 0 && _row$terminalList.includes(2) ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "width": "200",
            "trigger": "hover"
          },
          on: {
            "show": function show($event) {
              return _vm.getGzhQrcode(row);
            }
          }
        }, [_c('div', {
          staticClass: "qrcode-box y aic"
        }, [_c('el-image', {
          staticClass: "AppQrcode",
          attrs: {
            "src": row.gzhQrcode
          }
        }), _c('p', {
          staticClass: "x1 color9"
        }, [_vm._v("请使用微信扫描二维码")])], 1), _c('div', {
          staticClass: "qrcode mr20",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        }, [_c('img', {
          staticClass: "xcx",
          attrs: {
            "src": require("../../../assets/gzh.png")
          }
        }), _vm._v("公众号 ")])]) : _vm._e(), (_row$terminalList2 = row.terminalList) !== null && _row$terminalList2 !== void 0 && _row$terminalList2.includes(1) ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "width": "200",
            "trigger": "hover"
          },
          on: {
            "show": function show($event) {
              return _vm.getAppQrcode(row);
            }
          }
        }, [_c('div', {
          staticClass: "qrcode-box y aic"
        }, [_c('el-image', {
          staticClass: "AppQrcode",
          attrs: {
            "src": row.appQrcode
          }
        }), _c('p', {
          staticClass: "x1 color9"
        }, [_vm._v("请使用微信扫描二维码")])], 1), _c('div', {
          staticClass: "qrcode",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        }, [_c('img', {
          staticClass: "xcx",
          attrs: {
            "src": require("../../../assets/xcx.png")
          }
        }), _vm._v("小程序 ")])]) : _vm._e()], 1);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm.$userInfo.enterpriseType === 3 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showEditDialog(row);
            }
          }
        }, [_vm._v(" 配置 ")]) : _vm._e(), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.toPlatform(row);
            }
          }
        }, [_vm._v(" 打开管理后台 "), _c('i', {
          staticClass: "el-icon-copy-document"
        })])];
      }
    }])
  })], 1), _c('c-edit-dialog', {
    attrs: {
      "visible": _vm.editDialogVisible,
      "data": _vm.activeItem,
      "title": "配置聚合平台"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogVisible = $event;
      },
      "success": _vm.getAppList
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("聚合平台")])])]);

}]

export { render, staticRenderFns }
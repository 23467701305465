<template>
  <div class="platform base-container">
    <div class="header-mol">
      <div class="info">
        <div class="title">聚合平台</div>
      </div>
    </div>
    <el-table border class="body" :data="list">
      <el-table-column label="平台信息">
        <div slot-scope="scope" class="x aic">
          <el-image class="logo" :src="scope.row.logo" fit="cover">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <div style="height:50px" class="y3">
            <p class="fs16">
              {{ scope.row.name }}-{{ scope.row.applicationName }}
            </p>
            <p class="color9">
              {{ scope.row.enterpriseName }}，{{ scope.row.inDate }}
            </p>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="类型" width="200">
        <div slot-scope="scope">
          {{ scope.row.type == 1 ? "购房平台" : "分销平台" }}
        </div>
      </el-table-column>
      <el-table-column label="平台终端" width="210px">
        <div slot-scope="{ row }" class="x aic">
          <el-popover
            placement="bottom"
            width="200"
            trigger="hover"
            v-if="row.terminalList?.includes(2)"
            @show="getGzhQrcode(row)"
          >
            <div class="qrcode-box y aic">
              <el-image :src="row.gzhQrcode" class="AppQrcode"> </el-image>
              <p class="x1 color9">请使用微信扫描二维码</p>
            </div>
            <div class="qrcode mr20" slot="reference">
              <img src="../../../assets/gzh.png" class="xcx" />公众号
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="200"
            v-if="row.terminalList?.includes(1)"
            trigger="hover"
            @show="getAppQrcode(row)"
          >
            <div class="qrcode-box y aic">
              <el-image :src="row.appQrcode" class="AppQrcode"></el-image>
              <p class="x1 color9">请使用微信扫描二维码</p>
            </div>
            <div class="qrcode" slot="reference">
              <img src="../../../assets/xcx.png" class="xcx" />小程序
            </div>
          </el-popover>
        </div>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template v-slot="{ row }">
          <el-button
            type="text"
            v-if="$userInfo.enterpriseType === 3"
            @click="showEditDialog(row)"
          >
            配置
          </el-button>
          <el-button type="text" @click="toPlatform(row)">
            打开管理后台
            <i class="el-icon-copy-document"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <c-edit-dialog
      :visible.sync="editDialogVisible"
      :data="activeItem"
      title="配置聚合平台"
      @success="getAppList"
    ></c-edit-dialog>
  </div>
</template>

<script>
import { getMenu } from "@/api/setting";
import { getAppList, getGzhQrcode, getAppQrcode } from "@/api/platform";
import { uploadImg } from "@/api/common";
import CEditDialog from "./_components/edit";
import { encrypt } from "@/utils/crypto";
export default {
  components: {
    CEditDialog
  },
  data() {
    return {
      activeItem: {},
      list: [], //榜单列表
      editDialogVisible: false
    };
  },
  created() {
    this.getAppList();
  },
  methods: {
    async getAppList() {
      const data = await getAppList();
      this.list = data.map(item => {
        return {
          ...item,
          gzhQrcode: "", // 公众号二维码
          appQrcode: "" // 小程序二维码
        };
      });
    },
    async toPlatform(row) {
      //跳转到管理后台
      const { id, type } = row;
      // 购房平台
      if (type === 1) {
        this.$router.push({
          path: `/platform/estate/correl`,
          query: {
            platformId: encrypt(id)
          }
        });
        return;
      }
      // 获取分销平台菜单 跳转到第一个菜单
      // const res = await getMenu({ platformId: id });
      // if (res) {
      // const menus = res[0] && res[0].children ? res[0].children : [];
      this.$router.push({
        path: `/distribution/home`,
        query: {
          platformId: encrypt(id)
        }
      });
      // }
    },
    async getAppQrcode(row) {
      // 获取小程序二维码
      if (!row.appQrcode) {
        const res = await getAppQrcode({ id: row.id });
        if (res) {
          row.appQrcode = res;
        }
      }
    },
    async getGzhQrcode(row) {
      // 获取公众号二维码
      if (!row.gzhQrcode) {
        const res = await getGzhQrcode(row.id);
        if (res) {
          row.gzhQrcode = res;
        }
      }
    },
    async uploaderChange(file) {
      //上传图片
      let limitSize = 1024 * 1024 * 2;
      if (file.size > limitSize) {
        this.$showError("图片过大");
        return;
      }
      const res = await uploadImg({ file: file.raw });
      if (res) {
        this.formData.logo = res[0].fileUrl;
      }
    },
    async showEditDialog(row) {
      //点击创建
      this.activeItem = this.$deepClone(row);
      this.editDialogVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.platform {
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    .tips {
      margin-top: 10px;
      color: #999;
    }
  }
}

.logo {
  height: 55px;
  width: 55px;
  min-width: 55px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #aaa;
  background-color: #eee;
}

.xcx {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 5px;
}

.qrcode-box {
  padding: 10px 0;
  .el-image {
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
  }
  p {
    color: #8691a3;
  }
}

.rank-bgi-uploader {
  width: 120px;
  height: 100px;
  border: 1px dashed #ccc;
  line-height: 1;
  margin-right: 20px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  .img {
    width: 120px;
    height: 100px;
  }
  .add-btn {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    &.active {
      color: #ccc;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .el-icon-plus {
    margin-bottom: 15px;
    font-size: 32px;
    font-weight: bold;
    color: $primary;
  }
  &:hover {
    border-color: $primary;
  }
}
.tips {
  color: #8691a3;
}
.qrcode {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>

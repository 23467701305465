var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "700px"
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    staticClass: "form-item-flex",
    attrs: {
      "label": "平台标识：",
      "rules": [_vm.$formRules.required('请上传平台标识')],
      "prop": "logo"
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_c('div', {
    staticClass: "upload-group"
  }, [_c('f-upload', {
    attrs: {
      "required": "",
      "type": 2,
      "size": "mini",
      "default-file-list": _vm.formData.logo ? [_vm.formData.logo] : undefined
    },
    on: {
      "on-success": function onSuccess($event) {
        return _vm.logoUploadSuccess($event, 'logo');
      }
    }
  }), _vm.formData.type === 2 ? _c('el-form-item', {
    attrs: {
      "rules": [_vm.$formRules.required('请上传小程序首页logo')],
      "prop": "mpLogo"
    }
  }, [_c('f-upload', {
    attrs: {
      "required": "",
      "type": 2,
      "size": "mini",
      "default-file-list": _vm.formData.mpLogo ? [_vm.formData.mpLogo] : undefined
    },
    on: {
      "on-success": function onSuccess($event) {
        return _vm.logoUploadSuccess($event, 'mpLogo');
      }
    }
  })], 1) : _vm._e()], 1), _c('p', {
    staticClass: "tips"
  }, [_vm._v(" 图1：请上传应用标识图片，与微信小程序注册LOGO保存一致；图片必须清晰可见，尺寸尽量采用1:1规格，"), _c('br'), _vm.formData.type === 2 ? [_vm._v(" 图2：请上传小程序首页顶部logo图片；"), _c('br')] : _vm._e(), _vm._v(" 支持.jpg.jpeg.png.bmp格式；大小不超过2M； ")], 2)])]), _c('el-form-item', {
    attrs: {
      "label": "平台名称：",
      "rules": [_vm.$formRules.required()],
      "prop": "name"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('el-input', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.formData.applicationName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "applicationName", $$v);
      },
      expression: "formData.applicationName"
    }
  }), _c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "maxlength": "30"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)]), _vm.formData.type === 2 ? _c('el-form-item', {
    attrs: {
      "label": "业务配置：",
      "prop": "businessSetting",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.businessSetting,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "businessSetting", $$v);
      },
      expression: "formData.businessSetting"
    }
  }, _vm._l(_vm.businessList.slice(0, 2), function (item) {
    return _c('el-checkbox', {
      key: item.value,
      attrs: {
        "label": item.value,
        "disabled": item.value == 1
      }
    }, [_c('strong', [_vm._v(_vm._s(item.label))]), _c('span', [_vm._v(_vm._s(item.desc))])]);
  }), 1), _c('div', {
    staticClass: "choice-check"
  }, [_c('div', [_vm._v("以下业务只能二选一，不能同时授权")]), _c('el-checkbox-group', {
    model: {
      value: _vm.formData.businessSetting,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "businessSetting", $$v);
      },
      expression: "formData.businessSetting"
    }
  }, _vm._l(_vm.businessList.slice(2, 4), function (item) {
    return _c('el-checkbox', {
      key: item.value,
      attrs: {
        "label": item.value
      },
      on: {
        "change": _vm.changeRadio
      }
    }, [_c('strong', [_vm._v(_vm._s(item.label))]), _c('span', [_vm._v(_vm._s(item.desc))])]);
  }), 1)], 1)], 1) : _vm._e()], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:visible', false);
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }